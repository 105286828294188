/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
@import 'styles/css/vendors.bundle.css';
@import 'styles/css/app.bundle.css';
@import 'styles/css/fa-brands.css';
@import 'styles/css/fa-duotone.css';
@import 'styles/css/fa-light.css';
@import 'styles/css/fa-solid.css';
@import 'styles/css/site.css';
//@import 'assets/css/themes/cust-theme-1.css';
@import 'styles/css/skins/skin-master.css';
